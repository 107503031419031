import ky, { HTTPError, Options } from 'ky';
import { msalInstance, tokenRequestBase } from './msal';
import { t } from 'i18next';
import { PaginationQueryParameters } from '../types/core';

const DEFAULT_TIMEOUT = 1000 * 30;

export const createFilterParams = <T>(parameters: PaginationQueryParameters<T>): URLSearchParams | undefined => {
  if (!parameters) {
    return;
  }

  const params = new URLSearchParams();

  if (!!parameters.page) params.set("page", parameters.page.toString());
  if (!!parameters.pageSize) params.set("pageSize", parameters.pageSize.toString());
  if (!!parameters.sortField) params.set("sortField", parameters.sortField.toString());
  if (!!parameters.sortOrder) params.set("sortOrder", parameters.sortOrder.toString());

  for (const key of Object.keys(parameters.filterValues ?? {})) {
    const value = parameters.filterValues![key]
    for (let k in value) {
      params.append(key, value[k]);
    }
  }

  return params;
};

const getAccessToken = async (): Promise<string> => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const msalResponse = await msalInstance.acquireTokenSilent({
    ...tokenRequestBase,
    account: account,
  });

  return msalResponse.accessToken;
};

type OutputType = 'json' | 'blob';
export const fetchAuthenticated = async <T>(
  url: string,
  type: OutputType = 'json',
  config: Options = {},
  timeout: number = DEFAULT_TIMEOUT
): Promise<Blob | T> => {
  const accessToken = await getAccessToken();
  const headers = (config.headers as Headers) || new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  if (!headers.has('Content-Type')) {
    headers.append('Content-Type', 'application/json');
  }

  config.headers = headers;
  if (type === 'json') {
    return await ky(url, { ...config, timeout, retry: 0 }).json<T>();
  } else {
    return await ky(url, { ...config, timeout, retry: 0 }).blob();
  }
};

export const httpErrorConverter = async (error: unknown): Promise<string> => {
  if (!(error instanceof HTTPError)) {
    return t('unexpected', { ns: 'errors' });
  }

  const statusCode = error.response.status;
  let errorJson: { errors: ArrayLike<unknown> | { [s: string]: unknown }; message: never | string | string[] };
  try {
    errorJson = await error.response.json();
  } catch (e) {
    return t('unexpected', { ns: 'errors' });
  }

  switch (statusCode) {
    case 400:
      return Object.values(errorJson.errors).join('\n');
    case 401:
    case 403:
      return t(errorJson.message, { ns: 'errors' });
    case 404:
      return t('404.notfound', { ns: 'errors' });
    default:
      return t('unexpected', { ns: 'errors' });
  }
};
