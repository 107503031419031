import { useGetEnvironmentReport } from '@/lib/queries/reports';
import { TeeltplanIndex } from '@/lib/types/domain';
import { downloadBlob } from '@/lib/utils/downloadBlob';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect } from 'react';

interface DownloadEnvReportButtonProps {
  reportData?: TeeltplanIndex;
}

export const DownloadEnvReportButton: React.FunctionComponent<DownloadEnvReportButtonProps> = ({ reportData }) => {
  const { isLoading, data, refetch } = useGetEnvironmentReport(reportData?.id);

  const onClick = () => {
    refetch()
      .then(e => e.data)
      .then(blob => {
        if (blob && blob.type !== 'text/html') {
          downloadBlob(blob, `milieu_rapportage_${reportData?.deelnemer?.bedrijfsNaam}_${reportData?.registratieJaar}.xlsx`);
        }
      })
      .catch(err => {
        console.error(`failed to fetch report for teeltplan with id '${reportData?.id}': ${err}`);
      });
  };

  return <Button loading={isLoading} icon={<DownloadOutlined />} title="Milieurapport downloaden" onClick={onClick} />;
};
