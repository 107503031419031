import { NestedKeyOf } from "./table";

export enum SortOrder {
  Ascending,
  Descending,
}

export type FilterValues<T> = { [key in NestedKeyOf<T>]?: string[] }

export interface PaginationQueryParameters<T> {
  page?: number;
  pageSize?: number;
  sortOrder?: SortOrder;
  sortField?: keyof T;
  filterValues?: FilterValues<T>;
}

export interface PagedResponse<T> {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasNext: boolean;
  data: T[];
}

