import { QueryClient, useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { PagedResponse, PaginationQueryParameters } from '../types/core';
import { AggregatieRapportInput, EnvironmentReport, Review } from '../types/reports';
import { createFilterParams, fetchAuthenticated } from '../utils';
import { TeeltplanIndex } from '../types/domain';
import { BevoegdGezagOutput } from '../types/bevoegdGezag';

const QUERY_KEY = ['reports'];
const BASE_URL = import.meta.env.VITE_REPORT_API_URL;

export const useGetEnvironmentReports = (params?: PaginationQueryParameters<TeeltplanIndex>): UseQueryResult<PagedResponse<TeeltplanIndex>, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'environmentReportOverview', params],
    queryFn: () => fetchAuthenticated(`${BASE_URL}/rapportages/milieu?${createFilterParams(params ?? {})}`),
  });

export const useGetEnvironmentReport = (teeltPlanId?: number): UseQueryResult<Blob | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'environmentReports', teeltPlanId],
    retry: false,
    queryFn: () => (teeltPlanId ? fetchAuthenticated<Blob>(`${BASE_URL}/rapportages/milieu/${teeltPlanId}`, 'blob', {}, 120_000) : Promise.reject()),
    enabled: false, // Disabled so it only works on refetch
  });

export const useGetCompaniesReport = (year?: number, enabled: boolean = false): UseQueryResult<Blob | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'companies', year],
    retry: false,
    queryFn: () => (year ? fetchAuthenticated<Blob>(`${BASE_URL}/rapportages/bedrijven/${year}`, 'blob', {}, 120_000) : Promise.reject()),
    enabled: enabled,
  });

export const useGetBevoegdGezagsByTypeId = (id?: number, enabled: boolean = false): UseQueryResult<BevoegdGezagOutput[], Error> => {
  return useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => (id ? fetchAuthenticated<BevoegdGezagOutput[]>(`${BASE_URL}/bevoegdGezag/typeId/${id}`) : Promise.reject()),
    enabled: enabled,
  });
};

export const useGetAggregateGewasReport = (year?: number, enabled: boolean = false): UseQueryResult<Blob | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'aggregateGewas', year],
    retry: false,
    queryFn: () => (year ? fetchAuthenticated<Blob>(`${BASE_URL}/rapportages/aggregatie-gewas/${year}`, 'blob', {}, 120_000) : Promise.reject()),
    enabled: enabled,
  });

export const useGetAggregateOtherReport = (input?: AggregatieRapportInput, enabled: boolean = false): UseQueryResult<Blob | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'aggregateOther', input],
    retry: false,
    queryFn: () => (input ? fetchAuthenticated<AggregatieRapportInput>(`${BASE_URL}/rapportages/aggregatie?registratiejaar=${input.registratieJaar}
                                                                                                          &bevoegdGezagId=${input.bevoegdGezagId}
                                                                                                          &bevoegdGezagTypeCode=${input.bevoegdGezagTypeCode}`,
                                                                                                          'json', {}, 120_000) : Promise.reject()),
    enabled: enabled,
  });

export const useReviewEnvironmentReport = (): UseMutationResult<Review, Error, Review> => {
  const queryClient = new QueryClient();

  return useMutation<Review, Error, Review>({
    mutationFn: (review: Review) =>
      fetchAuthenticated<Review>(`${BASE_URL}/rapportages/milieu`, 'json', {
        json: review,
        method: 'PATCH',
      }) as Promise<Review>,
    mutationKey: [...QUERY_KEY],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY] });
    },
  });
};
