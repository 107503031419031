import { Page } from '@/lib/components/core/pageItems/page';
import { ReportSelect } from '@/lib/components/reports/reportSelect';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';


export const RequestReportPage: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');

  return (
    <Page title={t('pages.requestReport.title')}>
      <Typography.Paragraph>
        {t('pages.requestReport.body')}
      </Typography.Paragraph>
      <ReportSelect />
    </Page>
  );
};
