import { useReviewEnvironmentReport } from '@/lib/queries/reports';
import { TeeltplanIndex } from '@/lib/types/domain';
import { Review } from '@/lib/types/reports';
import { FormOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio } from 'antd';
import React, { useState } from 'react';

interface ReviewEnvReportButtonProps {
  reportData?: TeeltplanIndex;
  onOk: () => void;
}

export const ReviewEnvReportButton: React.FunctionComponent<ReviewEnvReportButtonProps> = ({ reportData, onOk }) => {
  const reviewEnvReport = useReviewEnvironmentReport();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const initialValues = {
    reason: reportData?.review?.reason,
    dateOfReview: reportData?.review?.dateOfReview,
    reviewStatusId: reportData?.review?.status,
    reviewStatusName: reportData?.review?.reviewStatusName,
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    values.teeltplanId = reportData?.id;
    values.datumVanVerwerking = new Date();
    reviewEnvReport.mutate(values, {
      onSuccess: () => {
        setIsModalVisible(false);
        onOk();
      },
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <>
      <Button icon={<FormOutlined />} disabled={reportData?.review?.status === 2} onClick={showModal} title="Milierapport keuren" />
      <Modal title="Milieurapport keuren" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ paddingBottom: '20px' }}>
          <p> Keur het milieurapport door gebruik te maken van onderstaande opties en geef extra toelichting wanneer nodig. </p>
        </div>
        <Form form={form} name="review" layout="vertical" initialValues={initialValues}>
          <Form.Item name="reviewStatusId" rules={[{ required: true, message: 'Selecteer een optie' }]} style={{ textAlign: 'center' }}>
            <Radio.Group>
              <Radio.Button value={2} style={{ margin: '5px' }}>
                Goedkeuren
              </Radio.Button>
              <Radio.Button value={3} style={{ margin: '5px' }}>
                Afkeuren
              </Radio.Button>
              <Radio.Button value={1} style={{ margin: '5px' }}>
                Onvolledig
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="reason" rules={[{ required: false }]}>
            <Input type="text" placeholder="Toelichting (niet verplicht)" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
