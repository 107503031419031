import { Button, Card, Space, Table, TablePaginationConfig, TableProps } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BasicTableColumn, BasicTableProps, FilterValues, PaginationQueryParameters, SortOrder } from "@/lib/types";
import { useEffect, useState } from "react";
import { FilterValue } from "antd/es/table/interface";
import { AnyObject } from "antd/es/_util/type";
import { ColumnSearchProps } from "./ColumnSearchProps";

export function BasicTable<T extends AnyObject>({ loadEntries, onEdit, columns, isLoading, selection, setSelection, updateHook }: BasicTableProps<T>) {
  const { t } = useTranslation('core');
  const [tableState, setTableState] = useState<PaginationQueryParameters<T>>({})
  const { data: pagedResponse, isLoading: isLoadingResponse, refetch } = loadEntries(tableState);

  useEffect(() => {
    if (updateHook)
      updateHook.current = refetch;
  }, [updateHook, refetch])

  const getColumns = (columns: BasicTableColumn<T>[]): TableProps<T>['columns'] => {
    if (!columns) return [];

    var map = columns.map(e => {
      const handleSearch = (confirm: () => void) => {
        confirm();
      };
      const handleReset = (clearFilters: (() => void) | undefined, confirm: () => void) => {
        clearFilters?.();
        confirm();
      };

      return {
        width: e.width,
        title: (e.label !== undefined) ? t(e.label?.toString()) : e.dataIndex.toString(),
        dataIndex: (e.dataIndex as string).split("."),
        render: e.render,
        key: e.key ?? e.dataIndex.toString(),
        ...(e.filterable ? ColumnSearchProps<T>(e.dataIndex, handleSearch, handleReset) : {}),
        sorter: e.sortable
      };
    })

    return !onEdit ? map : [
      ...map,
      {
        key: 'action',
        fixed: 'right',
        width: 120,
        render: (record: T) => <Button type="default" onClick={() => onEdit(record.id)} />,
      }
    ];
  }

  const onTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    const filteredColumns = Object.keys(filters).reduce(
      (result, key) => {
        if (filters[key] !== null) {
          result[key] = filters[key];
        }
        return result;
      },
      {} as Record<string, FilterValue | null>
    );

    const sortField = Array.isArray(sorter) ? undefined : sorter.field;
    const sortOrder = Array.isArray(sorter) ? undefined : sorter.order;

    let field = sortField as keyof T | undefined;
    let order: SortOrder | undefined;
    if (!!sortOrder) {
      order = sortOrder === "ascend" ? SortOrder.Ascending : SortOrder.Descending
    }

    setTableState({ filterValues: filteredColumns as FilterValues<T>, page: (pagination.current || 1), pageSize: pagination.pageSize, sortField: field, sortOrder: order });
  }

  return (
    <>
      {(!!selection && !!setSelection) && <Button style={{ marginBottom: 16 }} disabled={selection?.length == 0 && !!setSelection} onClick={() => { if (!!setSelection) { setSelection([]) } }}>Selectie resetten</Button>}
      <Table
        bordered={true}
        size="small"
        loading={isLoadingResponse || isLoading}
        onChange={onTableChange}
        dataSource={pagedResponse?.data.map(e => { return { key: e.id, ...e } }) || []}
        columns={getColumns(columns)}
        pagination={{
          current: (pagedResponse?.currentPage || 1),
          pageSize: pagedResponse?.pageSize || 10,
          total: pagedResponse?.totalItems || 0,
        }}
        rowSelection={!selection ? undefined : {
          type: 'checkbox',
          selectedRowKeys: [...selection.map(x => x.id)],
          onChange: (_: React.Key[], selectedRows: T[]) => {
            if (!!setSelection) {
              setSelection(selectedRows);
            }
          },
        }}
        scroll={{ x: 1200 }}
      />
    </>
  );
}
