import { FileTextOutlined, HomeOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './core.module.scss';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;

interface SiderProps {
  selectedKey: string;
  onSelect: (key: string) => void;
}

function getItem(label: string, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: string): MenuItem {
  return {
    label,
    key,
    icon: icon ? icon : null,
    children,
    type,
  } as MenuItem;
}

export const SideNav: React.FunctionComponent<SiderProps> = ({ selectedKey, onSelect }) => {
  const { t } = useTranslation('core');

  const items: MenuProps['items'] = useMemo(
    () => [
      getItem(t('menu.home'), '/', <HomeOutlined />),
      getItem(t('menu.reports.title'), '/reports', <FileTextOutlined />, [
        getItem(t('menu.reports.environment'), '/reports/environment'),
        getItem(t('menu.reports.request'), '/reports/request'),
      ]),
    ],
    [t]
  );

  const [collapsed, setCollapsed] = useState(false);
  return (
    <Sider className={styles.sideNav} collapsed={collapsed} width={250} onCollapse={value => setCollapsed(value)} collapsible breakpoint="lg">
      <Menu selectedKeys={[selectedKey]} defaultSelectedKeys={[selectedKey]} onSelect={e => onSelect(e.key)} mode="inline" items={items} />
    </Sider>
  );
};
